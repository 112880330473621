import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import BottleneckIcon from '../../assets/images/product/developer-productivity/bottleneck.svg'
import WorkingAgreementsTag from '../../assets/images/product/developer-experience/working-agreements-tag.svg'
import SlackTag from '../../assets/images/product/developer-experience/slack-tag.svg'
import CiTag from '../../assets/images/product/developer-experience/ci-tag.svg'
import DevOverviewTag from '../../assets/images/product/developer-productivity/developer-overview-tag.svg'
import Tag from '../../assets/images/product/developer-productivity/developer-productivity-tag.svg'
import HeartIcon from '../../assets/images/product/developer-productivity/heart.svg'
import MetaImage from '../../assets/images/product/developer-productivity/meta.png'
import MetricsTag from '../../assets/images/product/developer-productivity/metrics-tag.svg'
import PersonIcon from '../../assets/images/product/developer-productivity/person.svg'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import Br from '../../components/system/Br'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-productivity/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/developer-productivity/video-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    metrics: file(
      relativePath: {
        eq: "images/product/developer-productivity/engineering-metrics.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    workingAgreements: file(
      relativePath: {
        eq: "images/product/developer-experience/working-agreements.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    slack: file(
      relativePath: { eq: "images/product/developer-experience/slack.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    ci: file(
      relativePath: { eq: "images/product/developer-experience/ci.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    devOverview: file(
      relativePath: {
        eq: "images/product/developer-productivity/developer-overview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
  }
`

const ProductivityPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Developer productivity"
      variant="dark"
      isNew
      description="Identify and eliminate software delivery bottlenecks with a combination of research-backed metrics and developer tooling."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={Tag}
          title={
            <>Boost developer productivity with&nbsp;proven&nbsp;insights</>
          }
          content={
            <>
              Eliminate software delivery bottlenecks with a combination of
              research-backed metrics and developer tooling.
            </>
          }
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <LeadBlock
          heading={
            <>
              Improve productivity
              <Br />
              at the team-level
            </>
          }
          content="Every team is different. Swarmia helps you get rid of the systemic issues that are slowing down engineering teams."
        />
        <VideoBlock
          videoId="LcEJ-WK3prU"
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          thumbnail={getImage(data.videoThumbnail)!}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Squash delivery bottlenecks"
              text="Use Swarmia as an early warning system and proactively solve bottlenecks before they snowball into huge issues."
              icon={BottleneckIcon}
            />,
            <Blurb
              key="2"
              title="Coach team members"
              text="Make sure every engineer in your team has the context, support, and tools they need to do their best work."
              icon={PersonIcon}
            />,
            <Blurb
              key="3"
              title="Promote healthy ways of working"
              text="Facilitate better team ceremonies and encourage collaboration in and between engineering teams."
              icon={HeartIcon}
            />,
          ]}
        />
        <QuoteBlock person="matteo" />
        <LeadBlock
          icon={MetricsTag}
          heading="Measure the right things at every level of the organization"
          content="Establish the baseline for each team from a set of healthy, research-backed engineering productivity metrics."
          link={{
            title: 'Read more about engineering metrics',
            href: '/product/engineering-metrics/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.metrics)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={SlackTag}
          heading="Speed up development workflows"
          content="Never let PRs fall through the cracks again. Use the notifications to automate feedback loops, speed up code reviews, and more."
          image={getImage(data.slack)!}
          link={{
            title: 'Read more about Slack notifications',
            href: '/github-slack-integration/',
          }}
        />
        <LeadBlock
          icon={WorkingAgreementsTag}
          heading={<>Put continuous improvement on&nbsp;autopilot</>}
          content="Use the working agreements in Swarmia to adopt healthy habits and remain accountable to the targets you’ve adopted as a team."
          link={{
            title: 'Read more about working agreements',
            href: '/product/working-agreements/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.workingAgreements)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={CiTag}
          heading="Reduce CI wait times and costs"
          content="Find ways to speed up your CI/CD pipelines by drilling down into individual repositories and workflows."
          link={{
            title: 'Read more about CI insights',
            href: '/product/ci-cd-insights/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.ci)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={DevOverviewTag}
          heading="Make engineers’ work visible"
          content="Coach developers with the help of a blameless visualization that details what they’ve worked on and who they’ve worked with."
          link={{
            title: 'Read more about developer overview',
            href: '/product/developer-overview/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.devOverview)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="christian" />
        <Box paddingBottom={responsiveScale(32)}>
          <CTABlock
            title={
              <>
                Start systematically improving
                developer&nbsp;productivity&nbsp;today
              </>
            }
          />
          <FeaturedBlogArticles
            heading="More from the swarmia blog"
            articles={[
              '/blog/measuring-software-development-productivity/',
              '/blog/ship-software-10x-faster/',
              '/blog/bringing-visibility-into-a-developers-work/',
            ]}
            newStyles
          />
        </Box>
      </GlobalStyleReset>
    </Layout>
  )
}

export default ProductivityPage
